import React, { useState } from 'react';
import Slider from 'react-slick';
import appleStore from 'assets/images/apple-store.svg';
import googlePlayStore from 'assets/images/google-play-store.svg';
import appScreen1 from 'assets/images/app-screen1.jpg';
import appScreen1Webp from 'assets/images/app-screen1.jpg?as=webp';
import appScreen2 from 'assets/images/app-screen2.jpg';
import appScreen2Webp from 'assets/images/app-screen2.jpg?as=webp';
import appScreen3 from 'assets/images/app-screen3.jpg';
import appScreen3Webp from 'assets/images/app-screen3.jpg?as=webp';
import appScreen4 from 'assets/images/app-screen4.jpg';
import appScreen4Webp from 'assets/images/app-screen4.jpg?as=webp';
import LazyLoad from 'react-lazyload';
import loadable from '@loadable/component';

import { useSelector } from 'react-redux';
import { SUPPORTED_REGIONS } from '../../../shared/enums';
import { selectLocalizationData } from '../../features/localization/localizationSlice';

const LazyImg = loadable(() => import('../common/LazyImg'));
const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @param setSlider
 * @param imagesSettings
 * @returns {JSX.Element}
 * @constructor
 */

const AppBlockImageCarousel = ({ setSlider, imagesSettings }) => (
  <Slider className='app-block__image-carousel' ref={slider => setSlider(slider)} {...imagesSettings}>
    <figure className='app-block__image-slide'>
      <LazyLoad>
        <FadeIn>
          <picture>
            <source srcSet={appScreen1Webp} type='image/webp' />
            <source srcSet={appScreen1} type='image/jpg' />
            <img
              className='app-block__image'
              src={appScreen1}
              alt='app-screen1'
            />
          </picture>
        </FadeIn>
      </LazyLoad>
    </figure>
    <figure className='app-block__image-slide'>
      <LazyLoad>
        <FadeIn>
          <picture>
            <source srcSet={appScreen2Webp} type='image/webp' />
            <source srcSet={appScreen2} type='image/jpg' />
            <img
              className='app-block__image'
              src={appScreen2}
              alt='app-screen2'
            />
          </picture>
        </FadeIn>
      </LazyLoad>
    </figure>
    <figure className='app-block__image-slide'>
      <LazyLoad>
        <FadeIn>
          <picture>
            <source srcSet={appScreen3Webp} type='image/webp' />
            <source srcSet={appScreen3} type='image/jpg' />
            <img
              className='app-block__image'
              src={appScreen3}
              alt='app-screen3'
            />
          </picture>
        </FadeIn>
      </LazyLoad>
    </figure>
    <figure className='app-block__image-slide'>
      <LazyLoad>
        <FadeIn>
          <picture>
            <source srcSet={appScreen4Webp} type='image/webp' />
            <source srcSet={appScreen4} type='image/jpg' />
            <img
              className='app-block__image'
              src={appScreen4}
              alt='app-screen4'
            />
          </picture>
        </FadeIn>
      </LazyLoad>
    </figure>
  </Slider>
);

/**
 * @param setSlider
 * @param contentSettings
 * @returns {JSX.Element}
 * @constructor
 */
const AppBlockCardCarousel = ({ setSlider, contentSettings }) => (
  <Slider
    className='app-block__card-carousel'
    ref={slider => setSlider(slider)}
    {...contentSettings}
  >
    <div className='app-block__card-slide'>
      <h3 className='app-block__card-title'>
        Instantly see the air quality in every
        room.
      </h3>
      <div className='app-block__card-description'>
        <p>Multiple Milas allow you to keep tabs on the whole house.</p>
      </div>
      <span className='progress-bar' />
    </div>
    <div className='app-block__card-slide'>
      <h3 className='app-block__card-title'>Want an update? Just ask Mila.</h3>
      <div className='app-block__card-description'>
        <p>She’ll keep you updated on the status of your air throughout the day.</p>
      </div>
      <span className='progress-bar' />
    </div>
    <div className='app-block__card-slide'>
      <h3 className='app-block__card-title'>Know when you’re better off staying in. </h3>
      <div className='app-block__card-description'>
        <p>
          Mila tells you when the air quality dips outside,
          and adjusts automatically to keep you protected
          inside.
        </p>
      </div>
      <span className='progress-bar' />
    </div>
    <div className='app-block__card-slide'>
      <h3 className='app-block__card-title'>Find out if mold could be lurking.</h3>
      <div className='app-block__card-description'>
        <p>She monitors the humidity, and alerts you if you’re at risk of mold growth.</p>
      </div>
      <span className='progress-bar' />
    </div>
  </Slider>
);

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AppBlock = () => {
  const [ slider1, setSlider1 ] = useState(null);
  const [ slider2, setSlider2 ] = useState(null);

  const localization = useSelector(selectLocalizationData);
  const isRegionMatch = localization.region === SUPPORTED_REGIONS.CA;

  const contentSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    pauseOnFocus: false,
    focusOnSelect: true,
    asNavFor: slider2,
    responsive: [
      { breakpoint: 1024, settings: { vertical: false, verticalSwiping: false }},
      {
        breakpoint: 767,
        settings: {
          vertical: false, verticalSwiping: false, slidesToShow: 2, slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          vertical: false, verticalSwiping: false, slidesToShow: 1, slidesToScroll: 1,
        },
      },
    ],
  };

  const imagesSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    autoplay: false,
    speed: 1000,
    pauseOnHover: false,
    pauseOnFocus: false,
    cssEase: 'ease-in-out',
    asNavFor: slider1,
    responsive: [{ breakpoint: 767, settings: { infinite: true }}],
  };

  return (
    <section className='app-block'>
      <div className='container'>
        <div className='app-block__intro'>
          <h2 className='app-block__title'>
            So smart,
            <br />
            {' '}
            she makes air quality simple.
          </h2>
          <div className='app-block__description'>
            <p>
              The science of air quality is complicated.
              Mila explains things in plain language, using terms (and emojis)
              you can understand.
            </p>
          </div>
        </div>
        <div className='app-block__carousel-wrap'>
          <AppBlockImageCarousel setSlider={setSlider2} imagesSettings={imagesSettings} />
          <div className='app-block__card-wrapper'>
            <AppBlockCardCarousel setSlider={setSlider1} contentSettings={contentSettings} />
            <div className='app-link'>
              <a
                href={`${isRegionMatch ? 'https://apps.apple.com/ca/app/mila-air/id1520776049' : 'https://apps.apple.com/us/app/mila-air/id1520776049'}`}
                target='_blank'
                className='btn btn--black btn--apple-icon'
                rel='noreferrer'
              >
                <LazyImg
                  src={appleStore}
                  alt='App Store'
                />
                Download on App Store
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.mila.androidv2'
                target='_blank'
                className='btn btn--white'
                rel='noreferrer'
              >
                <LazyImg
                  src={googlePlayStore}
                  alt='Google Play Store'
                />
                Get it on Google Play
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppBlock;
